<template>
  <div>
      
      <div class="d-flex border-bottom pb-9 mb-9 align-items-end" v-if="filter">
           <div class="me-4">
              Durum
              <select name="" v-model="filter.status" id="" class="form-select" >
                  <option v-for="(status, i) in statuses" :key="i" :value="i">{{status}}</option>
              </select>
           </div>
           
        
           <div class="me-4">
              Arama
              <input type="text" class="form-control" v-model="filter.filter_word">
             
           </div>

           <div class="me-4" v-if="consultantType==6">
               Danışman Tipi
               
               <select name="" v-model="filter.consultant_type" id="" class="form-select">
                   <option value="0">Tümü</option>
                  <option v-for="consultant in consultantTypes" :key="consultant.consultantTypeId" :value="consultant.consultantTypeId">{{consultant.consultantType}}</option>
              </select>
               
           </div>
           
           <div>
                <input @click.prevent="sendFilter()" type="button" class="btn btn-info" value="Filtere">
           </div>
      </div>
  </div>
</template>

<script>
import api from '@/core/services/ApiService'
export default {
    name:'ColsultantFilterList',
    props:['defaultFilter','consultantType'],
    data(){
        return {
            consultantTypes:null,
           filter:{
                status:'JJ',
                filter_word:'',
                consultant_type:0
            },

            statuses:{
                'JJ':'Tümü',
                'AC':'Aktif',
                'DA':'Pasif'
            },
        }
    },
    
    methods:{

        getConsultantTypes:function(){
           return api.get('/User/ListDoctorType/AC').then((res)=>{
                this.consultantTypes = res.data;
            });
        },
        sendFilter:function(){
            this.$emit("filter",this.filter);
        }
    },

   

    mounted(){
        this.getConsultantTypes();
    }

   
}
</script>

<style>

</style>