<template>
    <div>
        <a  data-bs-target="#kt_modal_2" data-bs-toggle="modal" class="btn btn-sm btn-primary">
          Yeni Sistem Kullanıcısı Ekle
        </a>
    </div>
</template>
<script>
export default {
    name:'SystemUserToolbar',
}
</script>