<template>

  <div v-if="profile">
    
            <div class="card mb-5 mb-xl-12">
               
                <div id="kt_account_settings_profile_details" class="collapse show">
                    <form id="kt_account_profile_details_form" class="form">
                        <div class="card-body">

                            <div class="row mb-6">
                                <label class="col-lg-4 col-form-label required fw-bold fs-6">Adı Soyadı</label>
                                <div class="col-lg-8">
                                    <div class="row">
                                        <div class="col-lg-6 fv-row">
                                            <input type="text" name="name" v-model="profile.name" id="name" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="Adı" />
                                        </div>

                                        <div class="col-lg-6 fv-row">
                                            <input type="text" name="surname" v-model="profile.surname" class="form-control form-control-lg form-control-solid" placeholder="Soyadı" />
                                        </div>

                                       
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-6">
                                <label class="col-lg-4 col-form-label required fw-bold fs-6">E-posta / Telefon</label>
                                <div class="col-lg-8">
                                    <div class="row">
                                        <div class="col-lg-6 fv-row">
                                            <input type="email" name="email" v-model="profile.email" id="email" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" placeholder="E-posta adresi" />
                                        </div>

                                        <div class="col-lg-6 fv-row">
                                            <input type="tel" v-model="profile.phone" v-maska="'(###) ### ## ##'" class="form-control form-control-lg form-control-solid" placeholder="Telefon Numarası">
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-6">
                                <label class="col-lg-4 col-form-label required fw-bold fs-6">Cinsiyet / Doğum Tarihi / Şehir</label>
                                <div class="col-lg-8">
                                    <div class="row">
                                        <div class="col-lg-3 fv-row">
                                            <div class="row d-flex" style="height:100%">
                                                <div class="col-lg-6 fv-row d-flex align-items-center">
                                                    <label class="form-check form-check-custom form-check-solid inline">
                                                        <input class="form-check-input" v-model="profile.gender" type="radio" value="M">
                                                        <span class="form-check-label">Erkek</span>
                                                    </label>
                                                </div>
                                                <div class="col-lg-6 fv-row d-flex align-items-center">
                                                    <label class="form-check form-check-custom form-check-solid inline">
                                                        <input class="form-check-input" v-model="profile.gender" type="radio" value="F">
                                                        <span class="form-check-label">Kadın</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 fv-row">                                            
                                            <input type="date" v-model="profile.birthday" id="birthday" placeholder="Doğum Tarihi" class="form-control form-control-lg form-control-solid">
                                        </div>
                                        <div class="col-lg-5 fv-row">
                                            <input type="tel" v-model="profile.city" class="form-control form-control-lg form-control-solid" placeholder="Şehir" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                           


                            <div class="row" v-if="profile.userId">
                                <div class="col-lg-4 col-form-label fw-bold fs-6">Şifre</div>
                                <div class="col-lg-4 col-form-label fw-bold fs-6">
                                    <input type="text" name="password" class="form-control" v-model="profile.password">
                                </div>
                            </div>


                           
                            
                            <div class="row d-flex" style="height:100%">


                                <div class="col-lg-4 col-form-label fw-bold fs-6">Durum</div>
                                <div class="col-lg-6 fv-row d-flex align-items-center">
                                    <label class="form-check form-check-custom form-check-solid inline mr-10">
                                        <input class="form-check-input" name="status" :checked="profile.status=='AC'" v-model="profile.status" type="radio" value="AC">
                                        <span class="form-check-label">Aktif</span>
                                    </label>
                                    <label class="form-check form-check-custom form-check-solid inline">
                                        <input class="form-check-input" name="status" :checked="profile.status=='DA'" v-model="profile.status" type="radio" value="DA">
                                        <span class="form-check-label">Pasif</span>
                                    </label>
                                </div>
                            </div>

                        </div>

                      
                    </form>
                </div>
            </div>
    </div>

</template>

<script>
import moment from 'moment';
import { defineComponent } from "vue";
import { maska } from 'maska'

export default defineComponent({
  name: "ConsultantDetail",
  props:['profile'],
  
  directives: { maska },
  data(){
      return {
          consProfile : this.profile,
          consultantTypes:null
      }
  },
  methods:{
    
    moment(date){
        return moment(date)
    },
	save(){
        this.$emit("saved")
	},
    
    
  },
  mounted(){
      
      this.profile.birthday = moment(this.profile.birthday).format('YYYY-MM-DD');
  }
});

</script>

